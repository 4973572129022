import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Avatar from '@material-ui/core/Avatar';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import VideoSource from '../VideoSource';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';

import './style.scss';
import { renderTimestamp } from '../../utils/formatting';
import Icon from '../Icon';
import BottomSheet from '../bottom-sheet';
import { addToast as actionAddToast } from '../../actions';
import FeedCardActions from '../FeedCardActions';
import { Resize } from '../../utils/imageUtils';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import { Transducer } from '../../utils/transducer';
import ShareIntent from '../../utils/shareUtils';
import ShareItem from '../../models/ShareItem';
import axios from 'axios';
import { config } from '../../settings';
import Post from '../../models/Post';
const hashids = new Hashids('', 12);

// FIXME All of these cards should extend from a top level one that handles all the common things like saving items
class CardList extends PureComponent {
  constructor(props) {
    super(props);
    const { auth, item: post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand;

    if (item) {
      if (window.analytics) {
        window.analytics.track('Blog Post Impressions', {
          post_id: post.id,
          business_id: item && item.id,
          business_type: post.business_type,
          user_id: auth && auth.user && auth.user.id,
        });

        if (venue) {
          window.analytics.track('Venue Impressions', {
            venue_id: venue.id,
            user_id: auth && auth.user && auth.user.id,
            impression_type: 'user-generated',
          });
        }

        if (brand) {
          window.analytics.track('Brand Impressions', {
            brand_id: brand.id,
            user_id: auth && auth.user && auth.user.id,
            impression_type: 'user-generated',
          });
        }
      }
    }
  }

  moreOptions = [{
    content: 'Save for later',
    icon: 'bookmark',
    value: 'save',
    onClick: () => {
      const article = this.props.item;
      const { auth } = this.props;
      const { user } = auth;
      saveItem(user, 'articles', article);
      this.props.addToast({
        content: (<>{`Saved "${article.title}"`}</>),
        duration: 6000,
      });
    },
  }];

  getFeaturedImage = () => {
    const { item: post } = this.props;
    console.log('Getting image for post:');
    console.log(post);
    let imageURL = Transducer.getPreference('dark_mode') === 'true' ? PlaceholderDark.cigar : Placeholder.cigar;
    if (post.largeImage) {
      imageURL = Resize.size(post.largeImage);
    } else if (post.image) {
      imageURL = Resize.size(post.image);
    } else if (post.image_url) {
      // It's an external post from our business manager
      imageURL = Resize.size(post.image_url);
    }
    return imageURL;
  };

  toggleSavedItem = () => {
    let { item, auth } = this.props;
    const { user } = auth;
    item = JSON.parse(JSON.stringify(item));
    console.log('Toggling saved item for state:');
    if (!this.state.saved) {
      console.log('save');
      saveItem(user, 'article', item);
    } else {
      console.log('unsave');
      unsaveItem(user, {
        item_id: item.id,
        type: 'article',
      });
    }
    this.setState((prevState) => ({ saved: !prevState.saved }), () => {
      this.props.addToast({
        content: (<>{this.state.saved ? 'Saved to Articles' : 'Removed from Articles'}</>),
        duration: 6000,
      });
    });
  };

  getDisplayName = (post) => {
    let name = 'Cigars 365';
    console.log('Post:');
    console.log(post);
    if ((post.external_url && post.external_url.indexOf('cigarsnearme') === -1) || (post.link && post.link.indexOf('cigarsnearme') === -1)) {
      if (post.author && typeof post.author === 'object') {
        name = post.author.display_name;
      } else if (typeof post.author === 'string') {
        name = post.author;
      }
      // FIXME Else, it seems like it's their WP ID
    }
    return name;
  };

  getDisplayImage = (post) => {
    let image = 'https://cdn.cigarsnearme.com/assets/img/cigars-365-alexa.png';
    if ((post.external_url && post.external_url.indexOf('cigarsnearme') === -1) || (post.link && post.link.indexOf('cigarsnearme') === -1)) {
      image = post.author_image_url;
    }
    return image;
  };

  onNavigate = (e, url) => {
    e.preventDefault();
    const { auth, item: post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand;
    if (window.analytics) {
      window.analytics.track('Blog Post Clicks', {
        post_id: post.id,
        business_id: item && item.id,
        business_type: post.business_type,
        user_id: auth && auth.user && auth.user.id,
        url,
      });
    }
    window.open(url, '_blank');
  }

  onInternalNavigate = () => {
    const { auth, item: post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand;
    if (window.analytics) {
      window.analytics.track('Blog Post Clicks', {
        post_id: post.id,
        business_id: item && item.id,
        business_type: post.business_type,
        user_id: auth && auth.user && auth.user.id,
        url: post.external_url,
      });
    }
  }

  showProfile = (e) => {
    e.preventDefault();
    if (this.props.item.business_type && (this.props.item.venue || this.props.item.brand)) {
      const { auth } = this.props;
      const { venue, brand } = this.props.item;
      const item = venue || brand;
      window.analytics?.track(`${this.props.item.business_type.charAt(0).toUpperCase()}${this.props.item.business_type.substring(1, this.props.item.business_type.length)} Clicks`, {
        [`${this.props.item.business_type}_id`]: item && item.id,
        user_id: auth && auth.user && auth.user.id,
      });
      this.props.history.push(`/${this.props.item.business_type}s/${hashids.encode(item.id)}`);
    }
  };

  renderHeader = () => {
    const post = this.props.item;
    // let { author } = post;
    const author = {
      display_name: this.getDisplayName(post),
      image: this.getDisplayImage(post),
    };

    const date = post.date || post.timestamp;
    return (
      <div style={{ display: 'flex', backgroundColor: '#ffffff', padding: 5 }}>
        <Avatar
          src={author.image && Resize.size(author.image, { width: 28, height: 28, cropType: 'crop' })}
          alt={author.display_name}
          style={{ height: 28, width: 28, margin: '8px 12px', cursor: 'pointer' }}
          onClick={this.showProfile}
        >
          {author.display_name && author.display_name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }} onClick={this.showProfile}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important', cursor: 'pointer' }}>
            {author.display_name}
            {this.props.sponsored && <Badge pill style={{ marginLeft: 10, color: '#9f9f9f' }} className="card-header-badge">Sponsored</Badge>}
          </div>
          <div style={{ fontSize: 12 }}>
            {`Posted ${renderTimestamp(date)}`}
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={() => {
            BottomSheet.show({
              items: this.moreOptions,
            });
          }}
        >
          <Icon name="more-horizontal" />
        </IconButton>
      </div>
    );
  };

  renderInnerMedia = () => {
    const post = this.props.item;
    const imageURL = `url(${this.getFeaturedImage()})`;
    if (this.props.item.videoId) {
      return (
        <VideoSource
          videoSrc={post.videoUrl}
          // scaleWidth={608}
          scaleHeight={244}
          placeholder={post.image}
          title={post.title}
        />
      );
    }
    if (post.external_url) {
      if (post.external_url.indexOf('boxpressd') !== -1) {
        return (
          <Link to={`/articles/${hashids.encode(post.external_id)}`} onClick={this.onInternalNavigate}>
            <div
              className="img"
              style={{
                backgroundImage: imageURL,
                paddingTop: '100%',
              }}
            />
          </Link>
        );
      }
      return (
        <a
          href={post.external_url}
          target="_blank"
          onClick={(e) => this.onNavigate(e, post.external_url)}
        >
          <div
            className="img"
            style={{
              backgroundImage: imageURL,
              paddingTop: '100%',
            }}
          />
        </a>
      );
    }
    if (post.id) {
      return (
        <Link to={`/articles/${hashids.encode(post.id)}`} onClick={this.onInternalNavigate}>
          <div className="img" style={{ backgroundImage: imageURL, paddingTop: '100%' }} />
        </Link>
      );
    }
    return <div className="img" style={{ backgroundImage: imageURL, paddingTop: '100%' }} />;
  };

  renderMedia = () => (
    <div className="image">
      { this.renderHeader() }
      { this.renderInnerMedia() }
    </div>
  );

  render() {
    const post = this.props.item;
    // console.log('Got blog post');
    // console.log(post);

    let { author } = post;
    if (post.author && typeof post.author === 'object') {
      author = post.author.display_name;
    }
    const date = post.date || post.timestamp;

    if (!post.excerpt && post.content) {
      post.excerpt = `${post.content.substring(0, 200)}...`;
    }

    if (!post.intro && post.content) {
      post.intro = `${post.content.substring(0, 320)}...`;
    }

    return (
      <div className="feed-card-item">
        { this.renderMedia() }
        <div className="content">
          <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
            {post.external_url && post.external_url.indexOf('boxpressd') !== -1 && (
              <Link to={`/articles/${hashids.encode(post.external_id)}`} onClick={this.onInternalNavigate}>
                <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
              </Link>
            )}
            {post.external_url && post.external_url.indexOf('boxpressd') === -1 && (
              <a href={post.external_url} target="_blank" rel="noopener" onClick={(e) => this.onNavigate(e, post.external_url)}>
                <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
              </a>
            )}
            {!post.external_url && post.id && (
              <Link to={`/articles/${hashids.encode(post.id)}`} onClick={this.onInternalNavigate}>
                <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
              </Link>
            )}
            <div className="intro" dangerouslySetInnerHTML={{ __html: post.intro || post.excerpt }} />
          </div>
          <FeedCardActions
            postId={post.id}
            commentType="blog_article"
            commentRoute="articles"
            commentCount={this.props.commentCount}
            commentOnClick={this.props.compact ? () => {
              if (typeof this.props.onCommentClick === 'function') {
                this.props.onCommentClick();
              }
            } : null}
            likeCount={this.props.likeCount}
            onLikedToggle={(liked) => {
              console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
            }}
            onPostComment={(comment, parentId) => {
              console.log('Comment posted...');
              console.log(comment);
              axios.post(`${config.apiEndPoint}/blogs/articles/${post.id}/comments`, {
                business_post_id: post.id,
                // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
                comment: comment.replace('<br>', ''),
                user_id: this.props.auth.user.id,
                parent_id: parentId,
                comment_timestamp: new Date().toISOString(),
              });
            }}
            onShare={() => ShareIntent.share(new ShareItem({
              title: post.title && post.title.replace(/<[^>]*>?/gm, ''),
              text: `Check out this post by ${this.getDisplayName(post)} on Cigars Near Me`,
              path: 'article',
              route: `/articles/${hashids.encode(post.id)}}/comments`,
              image: this.getFeaturedImage(),
            }))}
          />
        </div>
      </div>
    );
  }
}

CardList.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
};

const mapStateToProps = (state)=> ({
  auth: state.get('auth').toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardList));
