import React, { useState } from 'react';
import './index.css';
import { language, messages } from '../../utils/localeUtils';

function Actionsheet(props) {
  const [showSheet, setShowSheet] = useState(false);
  const {
    title,
    message,
    onRequestClose,
    onCancel,
    cancelText,
    menus,
    show,
  } = props;

  setTimeout(() => {
    setShowSheet(show);
  }, 300);

  return (
    <div
      className={`react-actionsheet${showSheet ? ' react-actionsheet-show' : ''}`}
      onClick={onRequestClose}
    >
      <div className="react-actionsheet-mask">
      </div>
      <div className="react-actionsheet-wrap">
        <div className="react-actionsheet-menu">
          {(title || message) && (
            <div className="react-actionsheet-menu-item react-actionsheet-menu-item-header">
              {title && <div className="react-actionsheet-title">{title}</div>}
              {message && <div className="react-actionsheet-message">{message}</div>}
            </div>
          )}
          {menus.map((menu, i) => {
            const { content, onClick = () => {}, style } = menu;
            return (
              <div key={i} className="react-actionsheet-menu-item" onClick={onClick} style={style}>
                {content}
              </div>
            );
          })}
        </div>
        <div className="react-actionsheet-action">
          <div className="react-actionsheet-action-item" onClick={onCancel}>
            {cancelText}
          </div>
        </div>
      </div>
    </div>
  );
}

Actionsheet.defaultProps = {
  onRequestClose: () => {},
  onCancel: () => {},
  cancelText: messages[language]?.cancel || 'Cancel',
  show: false,
  menus: [],
};

// compiled bundle ignores "export default" values and exports empty object!!
// export default Actionsheet
export default Actionsheet;
