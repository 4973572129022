export const UPDATE_AUTH = 'UPDATE_AUTH';
export const updateAuth = (auth) => ({
  type: UPDATE_AUTH,
  auth,
});

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = (settings) => ({
  type: UPDATE_SETTINGS,
  settings,
});

export const ADD_TOAST = 'ADD_TOAST';
export const addToast = (data) => ({
  type: ADD_TOAST,
  data,
});

export const REMOVE_TOAST = 'REMOVE_TOAST';
export const removeToast = (id) => ({
  type: REMOVE_TOAST,
  id,
});

export const SET_SEARCH_ACTION = 'SET_SEARCH_ACTION';
export const setSearchAction = (data) => ({
  type: SET_SEARCH_ACTION,
  data,
});

export const RESET_SEARCH_ACTION = 'RESET_SEARCH_ACTION';
export const resetSearchAction = () => ({
  type: RESET_SEARCH_ACTION,
});

export const SET_SHOP_STATE = 'SET_SHOP_STATE';
export const setShopState = (data) => ({
  type: SET_SHOP_STATE,
  data,
});
