import React from 'react';
import { Redirect } from 'react-router-dom';
import Hashids from 'hashids/cjs';
import { usePersistedState } from '../../utils/usePersistedState';
import { AppStore } from '../../stores';
const hashids = new Hashids('', 12);

function EditorRouter() {
  const [session] = usePersistedState('activeSmokeSession', null);
  const pageWasReloaded = !AppStore.useState(s => s.sessionStarted);
  console.log('Editor session router:');
  console.log(session);
  // const pageWasReloaded = (
  //   (window.performance.navigation && window.performance.navigation.type === 1)
  //   || window.performance
  //     .getEntriesByType('navigation')
  //     .map((nav) => nav.type)
  //     .includes('reload')
  // );
  // FIXME What to do if it doesn't have a cigar attached?
  if (pageWasReloaded && session && session.scan && session.scan.cigar && session.scan.cigar.id) {
    console.log('Found cached session...');
    console.log(session);
    return <Redirect to={`/cigars/${hashids.encode(session.scan.cigar.id)}?action=smokenow`} />;
  }
  return null;
}

export default EditorRouter;
