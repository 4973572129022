import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { maybeUpdateCurrentUser } from '../../utils/axiosAuthHelper';
import { connect } from 'react-redux';
import { authUser } from '../../pages/Auth/actions';
const { App } = window.Capacitor.Plugins;

function AppUrlListener({ updateAuth }) {
  let history = useHistory();
  useEffect(() => {
    if (window.Capacitor.isNative) {
      App.addListener('appUrlOpen', async (data) => {
        // alert(JSON.stringify(data));
        const urlParams = new URLSearchParams(data.url);
        const token = urlParams.get('token');
        if (token) {
          await maybeUpdateCurrentUser(token).then(async (updatedUser, updatedTokenId) => {
            await updateAuth(updatedUser, updatedTokenId);
          });
        }
        let separator = '.com';
        if (data.url.indexOf('.app') !== -1) {
          separator = '.app';
        }
        const path = data.url.split(separator).pop();
        if (path) {
          // alert(`Got path: ${path}`);
          history.push(path);
        }
        // If no match, do nothing - let regular routing logic take over
      });
    }
  }, []);

  return null;
}

const mapDispatchToProps = (dispatch) => ({
  updateAuth: (currentUser, tokenId) => dispatch(authUser(currentUser, tokenId)),
});

export default connect(null, mapDispatchToProps)(AppUrlListener);
