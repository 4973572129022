import React from 'react';
import PropTypes from 'prop-types';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import Notification from 'react-notification-system';
import Icon from '../Icon';

import './style.scss';
import { redirectAuth } from '../../utils/redirect';

const notificationStyle = {
  NotificationItem: {
    DefaultStyle: {
      top: '80px',
    },
    info: {
      borderTop: '2px solid #d5c196',
      backgroundColor: '#fefefe',
      color: '#69798f',
    },
  },
  Title: {
    info: {
      color: '#d5c196',
    },
  },
};

class ActionIconGroup extends React.Component {
  constructor(props) {
    super(props);

    const {
      itemSaved, showPopover, showSnackBar,
    } = this.props;

    this.state = {
      itemSaved: itemSaved || false,
      showPopover: showPopover || false,
      showSnackBar: showSnackBar || false,
      anchorElement: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-string-refs
    this.notificationSystem = this.refs.notificationSystem;
  }

  togglePopover = (event) => {
    const { togglePopover } = this.props;
    let anchor = null;
    if (event) {
      anchor = event.currentTarget;
    }
    this.setState((prevState) => ({
      showPopover: !prevState.showPopover,
      anchorElement: anchor,
    }));
    if (typeof togglePopover === 'function') {
      togglePopover();
    }
  };

  toggleSnackBar = () => {
    const { toggleSnackBar } = this.props;
    if (typeof toggleSnackBar === 'function') {
      toggleSnackBar();
    } else {
      this.setState({ showSnackBar: !this.state.showSnackBar });
    }
  };

  toggleSavedItem = () => {
    const { auth } = this.props;
    if (auth.authenticated && auth.user) {
      this.setState({
        itemSaved: !this.state.itemSaved,
      });

      // FIXME Make this global so we can call it from the helper class
      if (isMobile) {
        this.toggleSnackBar();
      } else {
        this.notificationSystem.addNotification({
          title: 'Saved Items',
          message: this.state.itemSaved ? 'Removed from Saved Items' : 'Added to Saved Items',
          level: 'info',
        });
      }

      // INFO Autohide in the component is buggy - still shows when changing the state - this doesn't seem to be any better...
      // setTimeout(() => {
      //   this.setState({ showNotification: false });
      // }, 3000);

      if (typeof this.props.toggleSavedItem === 'function') {
        this.props.toggleSavedItem(!this.state.itemSaved);
      }
    } else {
      redirectAuth(window.location.href, false);
    }
  };

  shareTo = (service) => {
    this.togglePopover();
    if (typeof this.props.shareTo === 'function') {
      this.props.shareTo(service);
    }
  };

  renderShareHandler = () => (
    <div className="btn-group" style={{ padding: 8 }}>
      <button className="btn-icon" onClick={this.shareTo}>
        <div>
          {this.renderShareIcon()}
        </div>
      </button>
    </div>
  );

  renderNotification = () => {
    const { showSnackBar, itemSaved } = this.state;
    if (isMobile) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          key="saved-item-snackbar"
          open={showSnackBar}
          onClose={this.toggleSnackBar}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={(
            <span id="message-id">
              {this.renderSaveIcon()}
              {itemSaved ? 'Added to Saved Items' : 'Removed from Saved Items'}
            </span>
          )}
        />
      );
    }
    return (
      <div style={{ textAlign: 'left' }}>
        {/* eslint-disable */}
        <Notification ref="notificationSystem" style={notificationStyle}/>
        {/* eslint-enable */}
      </div>
    );
  };

  renderSaveIcon = () => {
    const { iconSize } = this.props;
    const { itemSaved } = this.state;

    if (isIOS) {
      return (
        <Icon name={itemSaved ? 'bookmark' : 'bookmark-border'} vendor="material" size={iconSize} alt="save" />
      );
    }

    if (isAndroid) {
      return (
        <Icon name={itemSaved ? 'bookmark' : 'bookmark-border'} vendor="material" size={iconSize} alt="save" />
      );
    }

    return (
      <Icon name={itemSaved ? 'bookmark' : 'bookmark-border'} vendor="material" size={iconSize} alt="save" />
    );
  };

  renderShareIcon = () => {
    const { iconSize } = this.props;

    if (isIOS) {
      return (
        <Icon name={['fas', 'share']} vendor="fa" size={iconSize} alt="share" />
      );
    }

    return (
      <Icon name="share" vendor="material" size={iconSize} alt="share" />
    );
  };

  renderCommentIcon = () => {
    const { iconSize } = this.props;

    if (isIOS) {
      return (
        <Icon name="message-circle" size={iconSize} alt="comment" />
      );
    }

    if (isAndroid) {
      return (
        <Icon name="comment" vendor="material" size={iconSize} alt="comment" />
      );
    }

    return (
      <Icon name="comment" vendor="material" size={iconSize} alt="comment" />
    );
  };

  render() {
    const { containerStyle, comments, commentHandler, share } = this.props;
    return (
      <div className="icon" style={containerStyle}>
        <div className="btn-group" style={{ padding: 8 }}>
          <button className="btn-icon" onClick={this.toggleSavedItem}>
            <div>
              {this.renderSaveIcon()}
            </div>
          </button>
        </div>
        {comments
          ? (
            <div className="btn-group hide-350" style={{ padding: 8 }}>
              <button className="btn-icon" type="button" onClick={commentHandler}>
                <div>
                  {this.renderCommentIcon()}
                </div>
              </button>
            </div>
          )
          : null}

        {share ? this.renderShareHandler() : null}
        {this.renderNotification()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

ActionIconGroup.propTypes = {
  dispatch: PropTypes.func,
  containerStyle: PropTypes.object,
  togglePopover: PropTypes.func,
  toggleSavedItem: PropTypes.func,
  toggleSnackBar: PropTypes.func,
  commentHandler: PropTypes.func,
  showPopover: PropTypes.bool,
  showSnackBar: PropTypes.bool,
  itemSaved: PropTypes.bool,
  shareItems: PropTypes.array,
  iconSize: PropTypes.number,
  shareTo: PropTypes.func,
  comments: PropTypes.bool,
  share: PropTypes.bool,
};

ActionIconGroup.defaultProps = {
  showPopover: false,
  showSnackBar: false,
  itemSaved: false,
  shareItems: null,
  iconSize: 22,
  comments: false,
  share: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionIconGroup);
