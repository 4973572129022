import React from 'react';
import Skeleton from '@material-ui/core/Skeleton';
import { isMobile } from 'mobile-device-detect';
import './style.scss';

class CardListSkeleton extends React.PureComponent {
  render() {
    return (
      <div className="feed-card-item" style={{ marginTop: 38 }}>
        <div className="image">
          <Skeleton height={233} width={'100vw'} />
        </div>
        <div className="content">
          <div className="content-right" style={{ minHeight: 285, minWidth: isMobile ? 'auto' : 400, paddingRight: 20 }}>
            <div style={{ paddingTop: 30, marginBottom: 20 }}>
              <Skeleton width={200} />
            </div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }
}

export default CardListSkeleton;
