import React from 'react';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import Product from '../../models/Product';
import { config } from '../../settings';
import { language, messages } from '../../utils/localeUtils';
import ModalDialog from '../ModalDialog';
import { saveItem } from '../../pages/SavedItems/helper';
import Icon from '../Icon';
import { SettingsStore } from '../../stores';

const hashids = new Hashids('', 12);

function BuyButton({ forwardRef, loading, product, style, showPrefix, auth }) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  let buyUrl = product.buyUrl;
  let price = product.msrp || 0;
  if (product.salePrice) {
    price = product.salePrice;
  } else if (product.sale_price) {
    price = product.sale_price;
  }

  if (buyUrl) {
    if (buyUrl.indexOf('?') === -1) {
      buyUrl = `${buyUrl}&utm_source=app.boxpressd.com`;
    } else {
      buyUrl = `${buyUrl}?utm_source=app.boxpressd.com`;
    }
  }

  if (product.affiliateUrl) {
    // FIXME Use our bxpr.us endpoint for affiliate links - if the link is bad, we should really update the state to
    //  reflect it so maybe do an axios call to check the response code
    buyUrl = product.affiliateUrl;
  }

  const getBuyLanguage = () => {
    if (product.auction) {
      return messages[language]?.starting_at || 'Starting at';
    }
    return messages[language]?.buy || product.availability;
  };

  const showChatWidget = () => {
    // FIXME Eventually, this should use our Virtual Lounge messenger to let the user speak directly with the shop owners
    // FIXME How to add the product info to the chat? I don't think this is it
    const options = {
      productId: product.id,
      productSku: product.reference,
      venueId: product.venue.id,
      productName: product.name,
    };
    if (window.tidioChatApi) {
      window.tidioChatApi.track('view_product', options);
      window.tidioChatApi.show();
      window.tidioChatApi.open();
    }
  };

  if (product.availability !== 'In Stock') {
    console.log('Product not in stock!');
    console.log(product);
    return (
      <button
        className="btn btn-secondary"
        style={{
          ...style,
        }}
        onClick={() => {
          const buttons = [{
            label: 'Dismiss',
            onClick: () => {
              ModalDialog.close();
            },
          }];
          // FIXME Eventually have them log in and them come back to save it for them?
          if (auth && auth.user) {
            buttons.push({
              color: 'primary',
              variant: 'contained',
              label: 'Save',
              onClick: () => {
                saveItem(auth.user, 'product', product);
                ModalDialog.close();
              },
            });
          }
          ModalDialog.show({
            title: 'Product Currently Unavailable',
            message: 'This product is not currently in stock. Would you like to save it for later?',
            buttons,
          });
        }}
      >
        {price > 0 && `${showPrefix ? getBuyLanguage() : ''} ${product.getFormattedPrice()}`}
      </button>
    );
  }

  if (product.venue.id !== 35712 && !product.venue.premium) {
    console.log('Product not for sale in our app!');
    console.log(product);
    return (
      <button
        className="btn btn-outline-success"
        style={{
          backgroundColor: darkMode ? '#17191d' : '#f7f8fb',
          ...style,
        }}
        onClick={() => window.open(buyUrl, '_blank')}
      >
        {price > 0 && `${showPrefix ? getBuyLanguage() : ''} ${product.getFormattedPrice()}`}
        <Icon name="external-link" style={{ marginLeft: 10 }} />
      </button>
    );
  }

  return (
    <button
      ref={forwardRef}
      className={`btn ${(!price || price === 0) ? 'btn-outline-success' : 'btn-success'} ${product.salePrice && product.salePrice > 0 ? 'boxpressd-add-item' : ''}`}
      style={{
        textAlign: 'center',
        fontSize: 14,
        display: 'inline-block',
        backgroundColor: (!price || price === 0) ? (darkMode ? '#17191d' : '#f7f8fb') : null,
        ...style,
      }}
      data-item-id={hashids.encode(product.id)}
      data-item-price={price}
      data-item-url={`${config.shopEndPoint}/products/${hashids.encode(product.id)}/verify`}
      data-item-description={product.description}
      data-item-image={Product.getImage(product)}
      data-item-name={Product.title(product)}
      data-item-package={product.packaging && product.packaging.type}
      data-item-count={product.packaging && product.packaging.quantity}
      data-item-size={Product.getVitolaSize(product)}
      data-item-shape={Product.getVitolaShape(product)}
      // TODO Best way to handle Box-Pressed or Tubo? In the CSV, they can be Bools under 'product_details' but in our db they are in the alias field
      data-item-boxpressed={Product.isVitolaBoxpressed(product)}
      data-item-tubo={Product.isVitolaTubo(product)}
      data-item-alias={Product.getVitolaAlias(product)}
      data-item-quantity="1"
      data-item-shippable="true"
      // data-item-vendor-id={product.venue ? product.venue.id : undefined}
      // data-item-vendor-name={product.venue ? product.venue.name : undefined}
      data-item-metadata={JSON.stringify({ vendor_id: product.venue ? product.venue.id : undefined, vendor_name: product.venue ? product.venue.name : undefined })}
      onClick={(e) => {
        if (!price || price === 0) {
          e.preventDefault();
          showChatWidget();
        }
      }}
    >
      {price > 0 && `${showPrefix ? getBuyLanguage() : ''} ${product.getFormattedPrice()}`}
      {!loading && price === 0 && 'Message For Pricing'}
    </button>
  );
}

BuyButton.defaultProps = {
  showPrefix: true,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(BuyButton);
